import React, { memo, useEffect, useState } from "react";
import clsx from "clsx";

import useRoomState from "../../../twilio/hooks/useRoomState/useRoomState";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import ToggleAudioButton from "./components/ToggleAudioButton";
import ToggleVideoButton from "./components/ToggleVideoButton";

import "./styles.css";

const AudioVideoControls = (): JSX.Element => {
  // const dispatch = useAppDispatch();
  // const isAudioEnabledDefault = useAppSelector(state => state.audioVideoControl.isAudioEnabled);
  // const [isAudioEnabledDefaultState, setisAudioEnabledDefaultState] = useState(
  //   isAudioEnabledDefault,
  // );
  const roomState = useRoomState();

  const isRoomConnected = roomState !== "disconnected";
  const isReconnecting = roomState === "reconnecting";
  const showControls = isRoomConnected;

  return (
    <div className={clsx("audio-video-container", { showControls })}>
      <ToggleAudioButton
        className="btn btn__left"
        activeClassName="btn--active"
        disabled={isReconnecting}
        isConnected={showControls}
      />
      <ToggleVideoButton
        className="btn btn__right"
        activeClassName="btn--active"
        disabled={isReconnecting}
        isConnected={showControls}
      />
    </div>
  );
};

export default memo(AudioVideoControls);
